@use 'sass:map';
@use '@angular/material' as mat;
@use 'node_modules/proleis-web-app/src/lib/theme/theme' as theme;
@use 'proleis-web-css/src/index' as proleis;

@import 'material-icons/iconfont/material-icons.css';

@include theme.create-tebis-dark-theme();
@include theme.create-tebis-light-theme();
@include theme.create-dark-theme();
@include theme.create-light-theme();

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

pw-default-layout {
  width: 100vw;
  height: 100vh;
}

highcharts-chart {
  display: block;
  width: 100% !important;
}

.content {
  padding: 24px;
}

mat-sidenav-container {
  padding: 0 !important;
}

mat-drawer-container {
  min-height: 100%;
}

@media only screen and (max-width: 599px) {
  .content {
    padding: 8px;
  }
}

.mat-menu-content {
  overflow: hidden;
}

.mat-button {
  text-transform: uppercase;
}

.fill-flex {
  flex: 1 1 auto;
}

$columnSizes2Col: (
  colSizeXs: 1fr,
  colSizeSm: 1fr,
  colSizeMd: repeat(2, 1fr),
  colSizeLg: repeat(2, 1fr),
  colSizeXl: repeat(2, 1fr)
);

.p4m-grid-2-col {
  @include proleis.pw-grid-container($columnSizes2Col, 16px);
}

$columnSizes3Col: (
  colSizeXs: 1fr,
  colSizeSm: 1fr,
  colSizeMd: repeat(2, 1fr),
  colSizeLg: repeat(3, 1fr),
  colSizeXl: repeat(3, 1fr)
);

.p4m-grid-3-col {
  @include proleis.pw-grid-container($columnSizes3Col, 16px);
}

$columnSizes4Col: (
  colSizeXs: 1fr,
  colSizeSm: 1fr,
  colSizeMd: repeat(2, 1fr),
  colSizeLg: repeat(4, 1fr),
  colSizeXl: repeat(4, 1fr)
);

.p4m-grid-4-col {
  @include proleis.pw-grid-container($columnSizes4Col, 16px);
}

.p4m-flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#sidenav-portal {
  width: 100%;
}

a {
  color: #2e6da4;
  text-decoration: underline;
  cursor: pointer;
}

.mw-70 {
  max-width: 100%;
}

@media only screen and (min-width: 1920px) {
  .mw-70 {
    max-width: 70%;
  }
}

.title-max-width {
  display: flex;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: anywhere;
  text-overflow: ellipsis;
}

.info-screen-layout {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  justify-content: stretch;
}

@media (max-width: 599.98px) {
  .info-screen-layout {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .info-screen-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) and (max-width: 1279.98px) {
  .info-screen-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1280px) and (max-width: 1919.98px) {
  .info-screen-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1920px) {
  .info-screen-layout {
    grid-template-columns: repeat(4, 1fr);
  }
}

.info-form {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
}

.info-form > div:nth-child(odd) {
  font-weight: bold;
}
